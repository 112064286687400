import React from 'react'
import Logo from '../../../assets/icons/logo-loading.png'
import classes from './Loading.module.scss';

function Loading() {
    return (
        <>
            <div className={`d-flex flex-column justify-content-center align-items-center vh-100 ${classes.loaderContainer}`}>
                <div className='position-relative d-flex justify-content-center my-2 w-100' style={{ width: "124px" }}>
                    {/* <img src={Logo} width={100} /> */}
                    <div className={`text-white ${classes.loadingLabel}`}>
                        {"LOADING YOUR EXPERIENCE"}
                    </div>
                </div>
                <div>
                    <div class={`spinner-grow text-white mx-2 ${classes.spinnerCustom}`} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class={`spinner-grow text-white mx-2 ${classes.spinnerCustom}`} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class={`spinner-grow text-white mx-2 ${classes.spinnerCustom}`} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loading

import React from 'react'
import classes from "./Text.module.scss"

const Text = ({ title = "", text = "" }) => {
  return (
    <div className={`${classes.trailerText}`}>
      <h4 className="mb-0">
        <div className={`${classes.trailerRect}`}></div>
        <span className={`${classes.titleText}`}>{title}</span>
      </h4>
      <p className={`${classes.bodyText} mb-0`}>
        {text}
      </p>
    </div>
  );
};

export default Text

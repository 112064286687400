export const SET_DRIVER_INFO = "SET_DRIVER_INFO";
export const SET_DRIVER_EXPERIENCE = "SET_DRIVER_EXPERIENCE";
export const SET_BOOKING_REFERNCE = "SET_BOOKING_REFERENCE"
export const SET_LOADED_DATA = "SET_LOADED_DATA";

export const setDriverInfo = (payload) => {
    return { type: SET_DRIVER_INFO, payload };
}

export const setDriverExperience = (payload) => {
    return { type: SET_DRIVER_EXPERIENCE, payload };
}

export const setBookingReference = (payload) => {
    return { type: SET_BOOKING_REFERNCE, payload };
}

export const setLoadedData = (payload) => {
    return { type: SET_LOADED_DATA, payload };
}
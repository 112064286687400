import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import driverReducers from './reducers/driver';
import imagesReducers from './reducers/images';
import videosReducers from './reducers/videos';
import trailerReducers from './reducers/trailer';
import videoReducers from './reducers/video';
import playerReducers from './reducers/player';
import itemReducers from './reducers/item'

const reducers = () => {
    //Store Enhancers
    const enhancers = [];

    // Redux Dev Tools (solo dev)
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
        if (typeof devToolsExtension === "function") {
            enhancers.push(devToolsExtension());
        }
    }

    // Middleware
    const middleware = [
        thunk,
    ];

    //Store
    const store = createStore(
        combineReducers({
            driver: driverReducers,
            trailer: trailerReducers,
            images: imagesReducers,
            videos: videosReducers,
            video: videoReducers,
            player: playerReducers,
            item: itemReducers
        }),
        {},
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    return store;
}

export default reducers;
import AmgExperience from '../../assets/images/experiences/amg-experience.jpg'
import PremExperience from '../../assets/images/experiences/amg-premium-experience-1.jpg'
import SuvExperience from '../../assets/images/experiences/4x4-experience.jpg'
import AdiExperience from '../../assets/images/experiences/adi-experience.jpg'
import Default from '../../assets/images/experiences/driver-experience.jpg';
export const EXPERIENCES = {
    IMAGES: {
        "AMG": AmgExperience,
        "PREM": PremExperience,
        "VXU": Default,
        "ADI": AdiExperience,
        "4X4": SuvExperience,
        "Driver": Default
    },
    LABELS: {
        "AMG": "MERCEDES-AMG",
        "PREM": "ULTIMATE AMG",
        "VXU": "Driving",
        "4X4": "OFF-ROAD",
        "ADI": "YOUNG DRIVER CIRCUITS"
    }
}
import { SET_ID_CURRENT_PLAYING } from "../actions/player";

const initialState = {
    currentIdVideoPlaying: null,
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_ID_CURRENT_PLAYING:
            return { ...state, currentIdVideoPlaying: payload };
        default:
            return state;
    }
}
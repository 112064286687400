import React from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { SocialIcon } from 'react-social-icons'
import useShareActions from '../../hooks/useShareActions';
import classes from "./CardMultiMedia.module.scss"
import {
    EmailIcon,
    FacebookIcon,
    WhatsappIcon,
    FacebookShareButton,
    EmailShareButton,
    WhatsappShareButton,
    TwitterShareButton
} from "react-share";

import { DiscoveryVideoPlayer } from '@hyperstream/discoveryvideoplayer'
import { setIdCurrentPlaying, setIsPaused, setIsPlayed, addVideo, removeVideo } from '../../../store/actions/video';
import useBookRefActions from '../../hooks/useBookRefActions';
import videojs from 'video.js';


const CardMultiMedia = ({ name = '', img = '', video = '', downloadUrl = '#', isTrailer = false, id = '' }) => {
    const { REACT_APP_URL } = process.env;

    const [showButtons, setShowButtons] = useState(false);
    const [videoPaused, setVideoPaused] = useState(true);
    const [videoEnded, setVideoEnded] = useState(true);
    const [showText, setShowText] = useState(true);
    const [hovered, setHovered] = useState(false);
    const [playerInstance, setPlayerInstance] = useState();
    const [fileType, setFileType] = useState();
    const [videoUrl, setVideoUrl] = useState();
    const share = useShareActions();
    const data = useBookRefActions();

    const { uuidexperience, bookreference } = useParams();
    const auth_token = data.getCookie("jwt");

    useEffect(() => {
        if (video && isTrailer) {
            setFileType('trailer');
        } else if (video && !isTrailer) {
            setFileType('video');
        } else if (!video && !isTrailer) {
            setFileType('image');
        }
    }, [img, video, isTrailer]);

    const videoOptions = {
        autoloop: false,
        autoplay: false,
        fluid: true,
        loop: false,
        controls: true,
        preload: 'auto',
        muted: false,
        preferFullWindow: true,
        responsive: true,
        durationDisplay: true,
        playbackRateMenuButton: true,
        sources: [{
            src: video,
            type: 'application/x-mpegURL',
            withCredentials: true
        }]
    };

    const dispatch = useDispatch();
    const currentPlayingId = useSelector((state) => state.video.currentIdVideoPlaying);
    const isPaused = useSelector((state) => state.video.videos[playerInstance?.id_]?.isPaused);
    const isPlayed = useSelector((state) => state.video.videos[playerInstance?.id_]?.isPlayed);

    const onGetPlayerInstance = (instance) => {
        instance.controlBar.hide();
        setPlayerInstance(instance);
    }

    const handlePlayToggle = () => {
        if (playerInstance.paused()) {
            if (currentPlayingId) {
                if (playerInstance?.id_ !== currentPlayingId) {
                    setVideoPaused(true);
                    dispatch(setIsPaused(true, currentPlayingId));
                    dispatch(setIsPlayed(false, currentPlayingId));
                    const currentPlaying = document.getElementById(currentPlayingId + '_html5_api');
                    currentPlaying.pause();
                }
                playerInstance.play();
                dispatch(setIdCurrentPlaying(playerInstance?.id_));
                dispatch(setIsPlayed(true, playerInstance?.id_));
                dispatch(setIsPaused(false, playerInstance?.id_));
                setVideoPaused(false);
                if (videoEnded) {
                    setVideoEnded(false);
                }
            } else {
                playerInstance.play();
                dispatch(addVideo(playerInstance?.id_));
                dispatch(setIdCurrentPlaying(playerInstance?.id_));
                dispatch(setIsPlayed(true, playerInstance?.id_));
                dispatch(setIsPaused(false, playerInstance?.id_));
                setVideoPaused(false);
                if (videoEnded) {
                    setVideoEnded(false);
                }
            }
        } else {
            playerInstance.pause();
            dispatch(setIsPaused(true, playerInstance?.id_));
            dispatch(setIsPlayed(false, playerInstance?.id_));
            setVideoPaused(true);
        }
    }

    const handleFullScreenToggle = () => {
        playerInstance.requestFullscreen();
    }

    const handleFullScreenChange = () => {
        if (playerInstance.paused()) {
            setVideoPaused(true);
        }
        else {
            setVideoPaused(false);
        }
        if (playerInstance.isFullscreen()) {
            playerInstance.controlBar.show();
            if (window.innerWidth > 991) {
                const videoContainer = document.getElementById(playerInstance?.id_);
                const controlBar = videoContainer.querySelector('.vjs-control-bar');
                controlBar.classList.add(`${classes.customControlBar}`);
            }
        } else {
            playerInstance.controlBar.hide();
        }
    }

    const handleIsShared = () => {
        share.isShared(id, uuidexperience);
    }

    const handleVideoEnded = () => {
        dispatch(removeVideo(playerInstance?.id_));
        dispatch(setIsPaused(true, playerInstance?.id_));
        dispatch(setIsPlayed(false, playerInstance?.id_));
        setVideoPaused(true);
        setVideoEnded(true);
        if (playerInstance.isFullscreen()) {
            playerInstance.exitFullscreen();
        }
    }

    useEffect(() => {
        if (video && isTrailer) {
            setFileType('trailer');
        } else if (video && !isTrailer) {
            setFileType('video');
        } else if (!video && !isTrailer) {
            setFileType('image');
        }
    }, [img, video, isTrailer]);

    useEffect(() => {
        playerInstance?.on('ended', handleVideoEnded);
        playerInstance?.on('fullscreenchange', handleFullScreenChange);
        return () => {
            if (playerInstance) {
                playerInstance.dispose();
            }
        };
    }, [playerInstance])

    useEffect(() => {
        if (isPaused) {
            setVideoPaused(isPaused);
        }
    }, [isPaused]);

    useEffect(() => {
        if (isPlayed && isPlayed === false) {
            setVideoPaused(false);
        }
    }, [isPlayed])

    return (
        <div
            className={`rounded-0 ${video ? classes.containerVideo : classes.containerImage} ${classes.customControlBar} ${hovered ? 'hovered' : ''} swiper-no-swiping`}
            onMouseEnter={() => {
                setShowButtons(true);
                setShowText(false);
                setHovered(true);
            }}
            onMouseLeave={() => {
                setShowButtons(false);
                setShowText(true);
                setHovered(false);
            }}
        >

            {videoOptions && video &&
                <DiscoveryVideoPlayer getPlayerInstance={onGetPlayerInstance} options={videoOptions} />
            }

            <div className={`${classes.overflowHover}`}>
                <div className="embed-responsive embed-responsive-16by9">
                    <div style={{ zIndex: 1 }} className={`card-img-overlay ${hovered ? 'hovered' : ''}`}>
                        {showButtons && (
                            <div>
                                <div className={`btn-group ${video ? classes.playerButtons : classes.downloadButton}`}
                                    role="group" aria-label="Basic example">
                                    {video &&
                                        <button
                                            id={"play button"}
                                            type="button"
                                            className={`${classes.button} ${classes.playButton} border-0 rounded-0 btn btn-primary btn-lg bi bi-caret-right-fill d-flex justify-content-center align-items-center`}
                                            onClick={handlePlayToggle}
                                        >
                                            {videoPaused &&
                                                <svg width="12" height="15" viewBox="0 0 12 15" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M0.486084 0.636597V14.6366L11.4861 7.6366L0.486084 0.636597Z"
                                                        fill="#FFFFFF" />
                                                </svg>
                                            }
                                            {!videoPaused &&
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                                    viewBox="0 0 320 512" fill='#FFFFFF'>
                                                    <path
                                                        d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z" />
                                                </svg>
                                            }

                                        </button>
                                    }
                                    <a
                                        type="button"
                                        className={`${classes.button} ${video ? '' : classes.downloadImageButton} border-0 rounded-0 btn btn-primary btn-lg bi bi-caret-right-fill d-flex align-items-center justify-content-center`}
                                        href={downloadUrl}
                                    >
                                        <span className={`${classes.downClass}`}>Download</span>
                                    </a>
                                </div>
                                <div className={`btn-group ${classes.fullScreenBtnGroup}`}>
                                    {video &&
                                        <button
                                            id={"full-screen"}
                                            type="button"
                                            className={`${classes.fullScreenButton} border-0 rounded-0 btn btn-sm bi bi-caret-right-fill d-flex align-items-center`}
                                            onClick={handleFullScreenToggle}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white"
                                                class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                                            </svg>
                                        </button>
                                    }
                                </div>
                            </div>
                        )}

                        {showButtons && (
                            <div className={`${classes.socialIcons}`}>
                                <FacebookShareButton
                                    beforeOnClick={handleIsShared}
                                    url={`${REACT_APP_URL}/share/${fileType}/${id}/${uuidexperience}`}
                                    hashtag='#mercedesbenzworld'><FacebookIcon size={30} round={false}
                                        className='mr-1 mt-1' /></FacebookShareButton>
                                <TwitterShareButton
                                    beforeOnClick={handleIsShared}
                                    hashtags={['mercedesbenzworld', 'experience', `Mercedes${name}`]}
                                    url={`${REACT_APP_URL}/share/${fileType}/${id}/${uuidexperience}`}
                                    className='mr-1 mt-1'><SocialIcon url="https://x.com"
                                        className={`rounded-0 mt-1 ${classes.socialIcon}`} /></TwitterShareButton>
                                <WhatsappShareButton
                                    beforeOnClick={handleIsShared}
                                    url={`${REACT_APP_URL}/share/${fileType}/${id}/${uuidexperience}`}>
                                    <WhatsappIcon
                                        size={30} round={false} className='mr-1 mt-1' /></WhatsappShareButton>
                                <EmailShareButton
                                    beforeOnClick={handleIsShared}
                                    subject='Mercedes-Benz World Experience'
                                    body='Experience a new dimension of driving at Mercedes-Benz World. Enjoy the performance of a Mercedes-Benz from the driver’s seat. Thrilling experiences for everyone to enjoy. Visit us on https://www.mercedes-benzworld.co.uk'
                                    separator='- Your experience url: '
                                    url={`${REACT_APP_URL}/share/${fileType}/${id}/${uuidexperience}`}><EmailIcon
                                        size={30} round={false} className='mr-1 mt-1' /></EmailShareButton>
                            </div>
                        )}

                        {showText && video && !isTrailer && (
                            <div className={`${classes.imageTextContainer}`}>
                                <p className={`${classes.imageText1} m-0`}>{!name && video ? 'Driver' : name}</p>
                                <p className={`${classes.imageText2} m-0`}>experience</p>
                            </div>
                        )}
                    </div>
                    {videoPaused || videoEnded ?
                        (<div
                            className={`embed-responsive-item ${hovered ? classes.imgHovered : classes.imgNotHovered} `}
                            style={{
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url(${img})`
                            }}>
                        </div>) : null
                    }
                </div>


            </div>


        </div>
    );
};

export default CardMultiMedia;
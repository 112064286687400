import React from 'react'
import classes from "./CardSection.module.scss"

const CardSection = ({ children }) => {
  return (
    <div className={`d-flex ${classes.trailerWrapper} justify-content-center flex-column ml-lg-5`}>
      {children}
    </div>
  );
};

export default CardSection
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import EnvironmentServices from './services/EnviromentServices';
import version from "../package.json";
const root = ReactDOM.createRoot(document.getElementById('root'));

if (EnvironmentServices.isProd()) {
  console.log = () => {
  }
  console.error = () => {
  }
  console.debug = () => {
  }
  console.info = () => {
  }
  console.warn = () => {
  }
}
console.log("💻 APP VERSION: ", version?.version)

root.render(
  <App />
);

reportWebVitals();

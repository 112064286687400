import React, { useEffect, useRef, useState } from 'react';
import useBookRefActions from '../../hooks/useBookRefActions';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import classes from './CardSlider.module.scss'
import CardImage from '../CardMultiMedia/CardMultiMedia'
import CustomLeftArrow from '../CustomLeftArrow/CustomLeftArrow';
import CustomRightArrow from '../CustomRightArrow/CustomRightArrow';
import { BREAKPOINTS } from '../../../config/resolutions/sliderConfig';
import { EXPERIENCES } from '../../../config/cards/cardImage';

function CardSlider({
    imgs = '',
    videos = '',
    isVideo = false,
    isTrailer = false,
    numItems = 1,
    downloadAll = '#',
    name = ''
}) {

    const navigationButton = {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    }

    const [swiperRef, setSwiperRef] = useState(null);

    const [imageList, setImageList] = useState();
    const [videoList, setVideoList] = useState();
    const [numSlides, setNumSlides] = useState(numItems);
    const data = useBookRefActions();
    const auth_token = encodeURIComponent(data.getCookie("jwt"));
    useEffect(() => {
        if (numItems) {
            setNumSlides(numItems);
        }
    }, [numItems])

    useEffect(() => {
        if (videos) {
            setVideoList(videos);
        }
    }, [videos]);

    useEffect(() => {
        if (imgs) {
            setImageList(imgs);
        }
    }, [imgs]);

    return (
        <>
            <Swiper
                onSwiper={setSwiperRef}
                navigation={navigationButton.navigation}
                modules={[Pagination, Navigation]}
                breakpointsBase='container'
                resizeObserver={true}
                breakpoints={isVideo ? BREAKPOINTS.VIDEOS : BREAKPOINTS.PHOTOS}
                className={`m-0 w-100 ${classes.mySwiper} ${classes.slider}`}
            >
                {imageList &&
                    imageList.map((image, index) => (
                        <SwiperSlide key={index}><CardImage img={image?.url?.replace("d2qenp69h99hhq.cloudfront.net", "dxm-test-experiences.mercedes-benzworld.co.uk")} isVideo={isVideo}
                            downloadUrl={image?.DownloadUrl}
                            id={image?.id} /></SwiperSlide>
                    ))
                }

                {videoList &&
                    videoList.map((video, index) => (
                        <SwiperSlide key={index}><CardImage
                            name={name ? name : EXPERIENCES.LABELS[video?.Vehicle] ? EXPERIENCES.LABELS[video?.Vehicle] : 'Driving'}
                            img={EXPERIENCES.IMAGES[video?.Vehicle] ? EXPERIENCES.IMAGES[video?.Vehicle] : EXPERIENCES.IMAGES["Driver"]}
                            video={video?.url?.replace("d2qenp69h99hhq.cloudfront.net", "dxm-test-experiences.mercedes-benzworld.co.uk")} isVideo={isVideo} isTrailer={isTrailer} downloadUrl={video?.DownloadUrl}
                            id={video?.id} /></SwiperSlide>
                    ))
                }

                <div
                    className={`d-flex w-100 justify-content-end`}>
                    <div
                        className={`swiper-button-prev ${classes.marginLeftArrow}`}>
                        <CustomLeftArrow /></div>
                    <div
                        className={`swiper-button-next ${classes.marginRightArrow}`}>
                        <CustomRightArrow /></div>
                </div>
            </Swiper>

            <div className='mx-5 ml-sm-0 ml-md-0 mx-lg-0 px-4 pl-sm-0 pl-md-0 px-lg-0 mb-5'>
                {downloadAll !== '#' &&
                    <a type='button' href={downloadAll}
                        className={`${classes.button} mx-4 mx-sm-0 mx-md-0 mx-lg-0 border-0 rounded-0 btn text-white btn-lg bi bi-caret-right-fill d-flex align-items-center`}>
                        <span>Download All</span>
                    </a>
                }
            </div>
        </>
    )
}

export default CardSlider

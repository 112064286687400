export const ADD_VIDEO = "ADD_VIDEO";
export const REMOVE_VIDEO = "REMOVE_VIDEO";
export const SET_IS_PLAYED = "SET_IS_PLAYED";
export const SET_IS_PAUSED = "SET_IS_PAUSED";
export const SET_ID_CURRENT_PLAYING = "SET_ID_CURRENT_PLAYING";

export const addVideo = (id) => {
    return { type: ADD_VIDEO, payload: { id } };
}

export const removeVideo = (id) => {
    return { type: REMOVE_VIDEO, payload: { id } };
}

export const setIsPlayed = (isPlayed, id) => {
    return { type: SET_IS_PLAYED, payload: { isPlayed, id } };
}

export const setIsPaused = (isPaused, id) => {
    return { type: SET_IS_PAUSED, payload: { isPaused, id } };
}

export const setIdCurrentPlaying = (payload) => {
    return { type: SET_ID_CURRENT_PLAYING, payload };
}
import React, { useState, useEffect } from 'react'
import Logo from '../../../assets/icons/mercedes-navbar.svg'
import classes from './navbar.module.scss'

function Navbar() {
    const [dark, setDark] = useState(false);

    const [hasScrolled, setHasScrolled] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setHasScrolled(true);
        } else {
            setHasScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        // <nav className={`navbar navbar-light navbar-container p-0 ${dark ? classes.customBgDark : ''}`} onMouseEnter={() => setDark(true)} onMouseLeave={() => { setDark(false) }}>
        //     <a className={`navbar-brand p-0`} href={"#"}>
        //         <img src={Logo} width={"128"} height={"128"} />
        //     </a>
        // </nav>
        <nav className={`navbar navbar-light navbar-container p-0 ${dark ? classes.customBgDark : classes.bgTransparent} ${hasScrolled ? classes.customBgDark : classes.bgTransparent}`} onMouseEnter={() => setDark(true)} onMouseLeave={() => { setDark(false) }}>
            <a className={`navbar-brand p-0`} href={"#"}>
                <img className={`${classes.imgSize}`} src={Logo} />
            </a>
        </nav>
    )
}

export default Navbar

import './style/App.scss';
import createStore from './store'
import Navbar from './components/core/Navbar/Navbar';
import Footer from './components/core/Footer/Footer';
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import AppRoute from './components/core/AppRoute/AppRoute';

export const store = createStore();

function App() {
  return (
    <StoreProvider store={store}>
      <BrowserRouter>
        <Navbar />
        <AppRoute />
        <Footer />
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;

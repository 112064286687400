import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useBookRefActions from '../../hooks/useBookRefActions';
import classes from './PasswordBox.module.scss';
import Arrow from '../../../assets/icons/arrow-button.svg';

function PasswordBox() {
    const [isLoading, setIsLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [attempts, setAttempts] = useState(0);
    const [waiting, setWaiting] = useState(false);
    const buttonRef = useRef(null);
    const navigate = useNavigate();
    const bookref = useBookRefActions();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uuidexperience = queryParams.get('uuid');
    const uuid = useSelector((state) => state.trailer.currentTrailerUuid);
    const isLoaded = useSelector((state) => state.driver.loadedData);

    useEffect(() => {
        if (isLoaded) {
            setIsLoading(false);
        }
    }, [isLoaded]);

    useEffect(() => {
        if (!isLoading && uuid) {
            navigate(`/gallery/${encodeURIComponent(uuidexperience)}`);
        }
    }, [uuid, isLoading]);

    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            buttonRef.current.click();
        }
    };

    const handleInputChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleClick();
    };

    const handleAttempts = async () => {
        if (attempts >= 5) {
            const lock = await bookref.lockUser(uuidexperience);
            setWaiting(true); // Set waiting state to true
            setTimeout(() => {
                setAttempts(0); // Reset attempts after waiting period
                setWaiting(false); // Reset waiting state
            }, 30000); // 30 seconds waiting period (adjust as needed)
        }
    }

    const handleClick = async () => {
        if (password === '') {
            setShowError(true);
        } else {
            if (!waiting) {
                const check = await bookref.isAllowed(password?.trim(), uuidexperience);
                if (!check) {
                    setShowError(true);
                    setAttempts(prevAttempts => prevAttempts + 1);
                    handleAttempts();
                    setTimeout(() => {
                        setShowError(false);
                    }, 5000);
                } else {
                    setShowError(false);
                }
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className='form-group'>
                    <label className={`${classes.passwordFormTitle}`} htmlFor="password">
                        {"Booking Reference."}
                    </label>
                    <input
                        type='password'
                        value={password}
                        onChange={handleInputChange}
                        onKeyUp={handleEnterKeyPress}
                        className={`form-control rounded-0 border-top-0 border-left-0 border-right-0 ${classes.inputPassword} mx-1`}
                        id='password'
                    />
                </div>

                <div className={`${classes.boxDescription}`}>
                    We've captured your most exciting twists and turns so you can relive your thrilling experience over and over again.
                    <br /><br />
                    Download, save, and share with family and friends or send directly to your social channels for all to check out what you've been up to.
                </div>

                <div className='text-center'>
                    <a
                        type='button'
                        ref={buttonRef}
                        tabIndex="0"
                        className={`btn rounded-0 ${classes.btnSubmit} ${password && !waiting ? classes.active : 'disabled'}`}
                        onClick={handleClick}
                    >
                        <svg className={`${classes.buttonChevron}`} width="6" height="10" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.794998 10.09L5.375 5.5L0.794998 0.91H2.88499L8.205 5.5L2.88499 10.09H0.794998Z" fill={`${password ? '#FFFFFF' : '#333333'}`} />
                        </svg>
                        Enter
                    </a>
                </div>

                {showError && !waiting &&
                    <div className={`${classes.boxError} text-center px-2 px-lg-0`}>
                        Please check and enter your valid booking reference.
                    </div>
                }

                {waiting &&
                    <div className={`${classes.boxError} text-center px-2 px-lg-0`}>
                        Number of attemps exceed! Please wait.
                    </div>
                }
            </form>
        </>
    );
}

export default PasswordBox;

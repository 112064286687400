import React from 'react'
import classes from './CustomRightArrow.module.scss'

function CustomRightArrow() {
    return (
        <button className={`${classes.buttonRight} border-0 rounded-0 btn btn-primary btn-lg bi bi-caret-right-fill`}>
            <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Arrow left" d="M4.57317 0L14.4817 12.8049L4.57317 25H0L8.84147 12.8049L0 0H4.57317Z" fill="white" />
            </svg>
        </button>
    )
}

export default CustomRightArrow

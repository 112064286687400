import React, { useState, useEffect } from 'react'
import { setDriverInfo, setDriverExperience, setBookingReference, setLoadedData } from '../../store/actions/driver';
import { setImageList } from '../../store/actions/images';
import { setVideoList } from '../../store/actions/videos';
import { setTrailerUrl, setTrailerUuid, setTrailerDownload, setTrailerList } from '../../store/actions/trailer';
import md5 from "md5";
import { sha256 } from 'js-sha256';
import { useDispatch, useSelector } from 'react-redux'
import driver from '../../store/reducers/driver';
import { useNavigate, useParams } from 'react-router-dom';
import EnvironmentServices from '../../services/EnviromentServices';

function useBookRefActions() {
    const apiUrl = EnvironmentServices.getApiUrl();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const lockUser = async (uuid) => {
        const settings = {
            method: 'POST',
            body: JSON.stringify({
                experienceId: uuid
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        };
        try {
            const response = await fetch(`${apiUrl}/disable`, settings);
            const data = await response.json();
        } catch (error) {
            console.error('Error: ', error);
            return null;
        }
    }

    const getAuth = async (bookRef, uuid) => {
        const settings = {
            method: 'POST',
            body: JSON.stringify({
                bookingReference: bookRef,
                uuidExperience: uuid
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        };

        try {
            const response = await fetch(`${apiUrl}/login`, settings);
            if (response.status === 403) {
                throw new Error('Locked');
            }
            const data = await response.json();
            return data?.token; // Return the JWT value
        } catch (error) {
            switch (error.message) {
                case 'Locked': navigate('error');
                default: return null;
            }
        }
    };

    const fetchData = async (bookingReference, uuid, auth_token) => {
        try {
            const response = await fetch(`${apiUrl}/mercedesExperience?pkValue=${bookingReference}&skValue=${uuid}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: "Basic " + auth_token
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            if (!data?.message?.Items?.[0]) {
                throw new Error('Not Data');
            }
            if (data?.message?.Items?.[0]?.json?.isExpired && data?.message?.Items?.[0]?.json?.isExpired === true) {
                throw new Error('Expired');
            }
            return data;
        } catch (error) {
            switch (error.message) {
                case 'Not data': return null; break;
                case 'Expired': return "expired"; break;
                case 'Locked': return "locked"; break; //Caso utente bloccato per via di tentativi superati
                default: return null;
            }
        }
    }

    const getData = (bookingReference, uuid, auth_token) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await fetchData(bookingReference, uuid, auth_token);
                if (data === 'expired') {
                    navigate('error/expired');
                }
                if (data === 'locked') {
                    navigate('/error');
                }
                if (getCookie("bookingReference")) {
                    saveData(data);
                }
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    const getCookie = (value) => {
        let cookie = {};
        document.cookie.split(';').forEach(function (el) {
            let [key, value] = el.split('=');
            cookie[key.trim()] = value;
        })
        return cookie[value];
    }

    const saveKeys = (bookingReference, uuid, auth_token) => {
        document.cookie = `bookingReference=${bookingReference}; path=/; domain=.mercedes-benzworld.co.uk; samesite=none; secure;`;
        document.cookie = `uuidExperience=${uuid}; path=/; domain=.mercedes-benzworld.co.uk; samesite=none; secure;`;
        document.cookie = `jwt=${auth_token}; path=/; domain=.mercedes-benzworld.co.uk; samesite=none; secure;`;
        return;
    }

    const saveData = (data) => {
        const driverName = data?.details?.driver_name;
        const driverExperience = data?.json?.Videos?.[0]?.Vehicle;
        const bookRef = data?.details?.booking_reference;
        const imageList = data?.json?.Images;
        const videoList = data?.json?.Videos;
        const trailerList = data?.json?.Trailers?.Items;
        const trailerUrl = data?.json?.Trailers?.Items?.[0]?.url;
        const trailerUuid = data?.json?.Trailers?.Items?.[0]?.id;
        const trailerDownload = data?.json?.Trailers?.Items?.[0]?.DownloadUrl;
        dispatch(setDriverInfo(driverName));
        dispatch(setDriverExperience(driverExperience));
        dispatch(setImageList(imageList));
        dispatch(setVideoList(videoList));
        dispatch(setTrailerList(trailerList));
        dispatch(setTrailerUrl(trailerUrl));
        dispatch(setTrailerUuid(trailerUuid));
        dispatch(setTrailerDownload(trailerDownload));
        dispatch(setBookingReference(bookRef));
        dispatch(setLoadedData(true));
        return;
    }

    const reloadData = async (bookingReference, uuid, auth_token) => {
        const data = await getData(bookingReference, uuid, auth_token);
        if (data === 'expired') {
            navigate('error/expired')
        }
        if (data === 'locked') {
            navigate('/error');
        }
        saveData(data?.message?.Items?.[0]);
        return;
    }

    const isAllowed = async (bookingReference, uuid) => {
        // const br_hash = md5(bookingReference);
        const br_hash = sha256(bookingReference);
        const auth_token = await getAuth(br_hash, uuid);
        const data = await getData(br_hash, uuid, auth_token);
        if (data !== null) {
            saveKeys(br_hash, uuid, auth_token);
            saveData(data?.message?.Items?.[0]);
            return true;
        }
        else {
            return false;
        }
    }

    return {
        isAllowed,
        lockUser,
        getCookie,
        getData,
        getAuth,
        fetchData,
        reloadData
    };
}

export default useBookRefActions

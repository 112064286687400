import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useBookRefActions from '../../hooks/useBookRefActions'
import { useNavigate, useParams } from 'react-router-dom'
import useData from '../../hooks/useData'
import VideoBackground from '../../../assets/videos/de-test-clip-1.mp4'
import MainTitle from '../MainTitle/MainTitle'
import Trailer from '../Trailer/Trailer'
import Text from '../Text/Text'
import CardSection from '../CardSection/CardSection'
import CardSlider from '../CardSlider/CardSlider'
import Loading from '../Loading/Loading'
import TrailerImage from '../../../assets/images/experiences/trailer-image.jpg'
import { DiscoveryVideoPlayer } from '@hyperstream/discoveryvideoplayer'
import classes from './Home.module.scss'

function Home() {

    const [isLoading, setIsLoading] = useState(true);

    const bookingReference = useSelector((state) => state.driver.currentBookingReference);
    const driverInfos = useSelector((state) => state.driver);
    const trailer = useSelector((state) => state.trailer);
    const isLoaded = useSelector((state) => state.driver.loadedData);
    const videos = useSelector((state) => state.videos);
    const images = useSelector((state) => state.images);

    const data = useData();
    const bookRef = useBookRefActions();
    const ref = useRef();
    const navigate = useNavigate();

    const { uuidexperience } = useParams();
    let trailerUrl = trailer.currentTrailerUrl;
    const cookieBookRef = bookRef.getCookie("bookingReference");
    const auth_token = bookRef.getCookie("jwt");

    const videoOptions = {
        autoloop: true,
        autoplay: true,
        fluid: true,
        loop: true,
        controls: false,
        preload: 'auto',
        muted: true,
        durationDisplay: false,
        playbackRateMenuButton: false,
        sources: [{
            src: VideoBackground,
            type: 'video/mp4'
        }]
    };

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' }); //azione per scrollare dal pulsante alla sezione trailer
    }

    useEffect(() => {
        if (!bookingReference) {
            if (cookieBookRef) {
                data.loadData();
            } else {
                navigate(`/?uuid=${encodeURIComponent(uuidexperience)}`);
            }
        }
    }, [])

    useEffect(() => {
        if (isLoaded) {
            setIsLoading(false);
        }
    }, [isLoaded]);

    useEffect(() => {
        if (!isLoading) {
            const heroMediaWrapperDiv = document.getElementById(`heroMediaWrapper`);
            const innerDiv = heroMediaWrapperDiv?.querySelector(".position-relative");
            if (innerDiv) {
                innerDiv.classList.remove('position-relative');
                innerDiv.classList.add(`${classes.divPlayer}`);
            }

            const vjsContainer = document?.querySelector('.vjs-container');
            if (vjsContainer) {
                vjsContainer.style.height = '100%';
            }

            const vjsDefaultSkin = document?.querySelector('.vjs-default-skin');
            if (vjsDefaultSkin) {
                vjsDefaultSkin.style.height = '100%';
            }
        }
    }, [isLoading])

    return (
        <>
            {isLoading &&
                <Loading />
            }

            {!isLoading &&
                <div className={`${classes.main}`}>
                    <div className={`${classes.heroComponent} ${classes.componentDark}`}>
                        <div className={`${classes.heroContainer} container-fluid`}>
                            <div className={`${classes.heroBody}`}>
                                <div className={`container-fluid px-2 px-sm-5 py-5 mx-2 mx-sm-0`}>
                                    <div className={`row mx-md-5 px-0 px-md-0 px-xl-5 ${classes.titleLocation}`}>
                                        <div className='col-12 d-flex flex-column px-2 px-md-0 px-lg-3 px-xl-5'>
                                            <MainTitle isLogin={false} name={driverInfos?.currentDriverInfo} />
                                            <div className={`${classes.subtitleHero}`}>You're a click away from experiencing your thrilling day driving on the tracks.</div>
                                            <div>
                                                <a type='button' className={`button btn rounded-0 ${classes.btnSubmit}`} onClick={handleClick}>
                                                    <svg className={`${classes.buttonChevron}`} width="6" height="10" viewBox="0 0 9 11" focusable="false" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.794998 10.09L5.375 5.5L0.794998 0.91H2.88499L8.205 5.5L2.88499 10.09H0.794998Z" fill='#FFFFFF' />
                                                    </svg>
                                                    <span>Click here</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.heroMediaWrapper}`} id='heroMediaWrapper'>
                            {!isLoading &&
                                <DiscoveryVideoPlayer options={videoOptions} />
                            }
                        </div>
                    </div>
                    <div className='container-fluid my-2 w-100'>
                        <div ref={ref} className={`row pt-5`}>
                            <CardSection>
                                <Text title={'Trailer'} text={'A round up of your day behind the wheel at Mercedes-Benz World.'} />
                            </CardSection>
                        </div>
                        <div className={`row pb-5 px-5 ${classes.paddingSlider}`}>
                            <CardSlider videos={trailer?.currentTrailerList} isVideo={true} isTrailer={true} numItems={trailer?.currentTrailerList?.length} name={driverInfos?.currentDriverInfo} />
                        </div>
                        <div className={`row ${classes.bgWhite} pt-5`}>
                            <CardSection>
                                <Text title={'Videos'} text={'All your driving action had been captured on camera, so relive and share your experience online.'} />
                            </CardSection>
                        </div>
                        <div className={`row ${classes.bgWhite} pb-5 ${classes.paddingSlider}`}>
                            <CardSlider videos={videos?.currentVideoList?.Items} isVideo={true} numItems={videos?.currentVideoList?.Items.length} downloadAll={videos?.currentVideoList?.downloadAll} />
                        </div>
                        <div className={`row pt-5`}>
                            <CardSection>
                                <Text title={'Photos'} text={"Check out your photos we've captured during your drive."} />
                            </CardSection>
                        </div>
                        <div className={`row px-5 ${classes.paddingSlider}`}>
                            <CardSlider imgs={images?.currentImageList?.Items} isVideo={false} numItems={images?.currentImageList?.Items.length} downloadAll={images?.currentImageList?.downloadAll} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Home

export const BREAKPOINTS = {
    VIDEOS: {
        1600: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 10
        },
        1200: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 10
        },
        780: {
            slidesPerView: 2,
            spaceBetween: 10,
            slidesPerGroup: 2
        },
        575: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 10
        },
        0: {
            slidesPerView: 1,
            spaceBetween: 5,
            slidesPerGroup: 1
        },
    },
    PHOTOS: {
        1600: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 10
        },
        1200: {
            slidesPerView: 2,
            slidesPerGroup: 3,
            spaceBetween: 10
        },
        780: {
            slidesPerView: 2,
            slidesPerGroup: 3,
            spaceBetween: 10
        },
        575: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 10
        },
        0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 10
        }
    }
}
export const SET_TRAILER_URL = "SET_TRAILER_URL";

export const SET_TRAILER_UUID = "SET_TRAILER_UUID";

export const SET_TRAILER_DOWNLOAD = "SET_TRAILER_DOWNLOAD";

export const SET_TRAILER_LIST = "SET_TRAILER_LIST";

export const setTrailerUrl = (payload) => {
    return { type: SET_TRAILER_URL, payload };
}

export const setTrailerUuid = (payload) => {
    return { type: SET_TRAILER_UUID, payload };
}

export const setTrailerDownload = (payload) => {
    return { type: SET_TRAILER_DOWNLOAD, payload };
}

export const setTrailerList = (payload) => {
    return { type: SET_TRAILER_LIST, payload };
}
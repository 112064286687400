import { SET_VIDEO_LIST } from "../actions/videos";

const initialState = {
    currentVideoList: null,
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_VIDEO_LIST:
            return { ...state, currentVideoList: payload };
        default:
            return state;
    }
}
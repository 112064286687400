import React from 'react'
import classes from './MainTitle.module.scss'

function MainTitle({ isLogin = false, name = "" }) {
    return (
        <>
            {isLogin &&
                <div className={`${classes.mainLoginTitleBox}`}>
                    <h1 className={`${classes.loginTitle} text-center`}>
                        Mercedes-Benz World
                    </h1>
                </div>
            }

            {!isLogin &&
                <div className={`${classes.mainTitleBox}`}>
                    <h1 className={`${classes.homeTitle}`}>
                        Hi {name}. <br />
                        Relive your experience
                    </h1>
                </div>
            }
        </>
    )
}

export default MainTitle

import React from 'react'
import Login from '../../components/core/Login/Login'

function Logpage() {
    return (
        <Login />
    )
}

export default Logpage

import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import CustomLeftArrow from '../core/CustomLeftArrow/CustomLeftArrow';
import CustomRightArrow from '../core/CustomRightArrow/CustomRightArrow';
import { DiscoveryVideoPlayer } from '@hyperstream/discoveryvideoplayer';
import { BREAKPOINTS } from '../../config/resolutions/sliderConfig';
import classes from '../core/CardMultiMedia/CardMultiMedia.module.scss'
import VideoBackground from '../../assets/videos/de-test-clip-1.mp4';

function AreaTest() {

    const navigationButton = {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    }

    const videoOptions = {
        overlayError: true,
        play: true,
        autoplay: false,
        fluid: true,
        responsive: true,
        controls: true,
        preload: 'auto',
        muted: true,
        durationDisplay: false,
        playbackRateMenuButton: false,
        sources: [{
            src: VideoBackground,
            type: 'video/mp4'
        }]
    };

    const [swiperRef, setSwiperRef] = useState(null);

    const array = [1, 2, 3, 4, 5];

    return (
        <>
            <Swiper
                onSwiper={setSwiperRef}
                navigation={navigationButton.navigation}
                modules={[Pagination, Navigation]}
                resizeObserver={true}
                breakpoints={BREAKPOINTS.VIDEOS}
                className={`m-0 w-100 ${classes.mySwiper} ${classes.slider}`}>

                <SwiperSlide>
                    <div className={`order-1 mb-auto mb-md-0  d-flex flex-column justify-content-center order-md-1`} style={{ background: "black" }}>
                        <div>
                            <div
                                className={`ml-auto mr-auto}`} style={{ maxWidth: '768px' }}>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <div className="embed-responsive-item videoPlayer">
                                        <div className={"position-relative swiper-no-swiping"}>
                                            <DiscoveryVideoPlayer options={videoOptions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <div
                    className={`d-flex w-100 justify-content-end`}>
                    <div
                        className={`swiper-button-prev ${classes.marginLeftArrow}`}>
                        <CustomLeftArrow /></div>
                    <div
                        className={`swiper-button-next ${classes.marginRightArrow}`}>
                        <CustomRightArrow /></div>
                </div>
            </Swiper>
        </>
    )
}

export default AreaTest

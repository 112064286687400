import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useBookRefActions from './useBookRefActions'
import useShareActions from './useShareActions';

function useData() {
    const bookRefActions = useBookRefActions();
    const shareActions = useShareActions();
    const [item, setItem] = useState();

    const loadData = () => {
        const cookieBookRef = bookRefActions.getCookie("bookingReference");
        const uuidExperience = bookRefActions.getCookie("uuidExperience");
        const auth_token = bookRefActions.getCookie("jwt");
        bookRefActions.reloadData(cookieBookRef, uuidExperience, auth_token);
    }

    const loadSharedData = (mediaId, experienceId) => {
        // const auth_token = bookRefActions.getAuth();
        shareActions.reloadData(mediaId, experienceId);
    }

    return { loadData, loadSharedData };
}

export default useData

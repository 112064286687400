import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import Logpage from "../../../pages/Logpage/Logpage"
import Share from "../../../pages/Share/Share"
import Gallery from "../../../pages/Gallery/Gallery"
import AreaTest from "../../test/AreaTest"
import ErrorPage from "../../../pages/Error/ErrorPage"


function AppRoute() {
    return (
        <Routes>
            <Route index element={<Logpage />} />
            <Route path="/" element={<Logpage />} />
            <Route path="share/:filetype/:uuid/:uuidexperience" element={<Share />} />
            <Route path="gallery/:uuidexperience" element={<Gallery />} />
            <Route path="error/expired" element={<ErrorPage missingUuid={false} brExpired={true} />} />
            <Route path="/error" element={<ErrorPage missingUuid={true} brExpired={false} />} />
            <Route path="*" element={<Navigate replace to={"/"} />} />
            {/*TEST PAGE*/}
            <Route path="/test" element={<AreaTest />} />
        </Routes>
    )
}

export default AppRoute

import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar/Navbar'
import MainTitle from '../MainTitle/MainTitle'
import PasswordBox from '../PasswordBox/PasswordBox'
import { useLocation, useNavigate } from 'react-router'
import ImgBackground from '../../../assets/images/login-background.png'
import Footer from '../Footer/Footer'
import classes from './Login.module.scss'

function Login() {
    const navigate = useNavigate();

    const [uuidExp, setUuidExp] = useState();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uuidexperience = queryParams.get('uuid');

    useEffect(() => {
        if (!uuidexperience) {
            navigate('/error');
        }
    }, [uuidexperience]);



    return (
        <>
            <div className={`${classes.background}`} id='background-image'>
                <img src={ImgBackground}></img>
            </div>
            <div className={`d-flex position-relative justify-content-center align-items-center pt-5 w-100`}>
                <div className='my-5 py-5'>
                    <MainTitle isLogin={true} />
                    <PasswordBox />
                </div>
            </div>
        </>
    )
}

export default Login

import React from 'react'
import classes from './Error.module.scss';

function Error({ missingUuid = true, brExpired = false }) {

    return (
        <>
            <div className={`${classes.backgroundContainer}`}>

            </div>
            <div className={`d-flex justify-content-center align-items-center ${classes.containerHeight}`}>
                <div className='d-flex flex-column text-center text-md-left'>
                    {/* <div className='mx-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div> */}

                    {brExpired &&
                        <>
                            <h1>Oops, your booking reference has expired!</h1>
                            <span className={`${classes.paragraph}`}>Click <a className={`text-secondary ${classes.linkError}`} href='https://mercedes-benzworld.co.uk'>
                                here
                            </a> to book a new experience.</span>
                        </>
                    }

                    {missingUuid &&
                        <>
                            <h1>Oops, page not found.</h1>
                            <span className={`${classes.paragraph}`}>There is a problem with the page you are looking for,</span>
                            <span className={`${classes.paragraph}`}>please check again.</span>
                        </>
                    }
                </div>
            </div >
        </>
    )
}

export default Error

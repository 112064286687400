import { SET_TRAILER_URL, SET_TRAILER_UUID, SET_TRAILER_DOWNLOAD, SET_TRAILER_LIST } from "../actions/trailer";

const initialState = {
    currentTrailerUrl: null,
    currentTrailerUuid: null,
    currentTrailerDownload: null,
    currentTrailerList: null
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_TRAILER_URL: {
            return { ...state, currentTrailerUrl: payload };
        }
        case SET_TRAILER_UUID: {
            return { ...state, currentTrailerUuid: payload }
        }
        case SET_TRAILER_DOWNLOAD: {
            return { ...state, currentTrailerDownload: payload }
        }
        case SET_TRAILER_LIST: {
            return { ...state, currentTrailerList: payload }
        }
        default:
            return state;
    }
}
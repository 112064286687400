import { SET_ITEM_URL, SET_ITEM_ID, SET_ITEM_DOWNLOAD, SET_LOADED_ITEM } from "../actions/item";

const initialState = {
    currentItemUrl: null,
    currentItemId: null,
    currentItemDownload: null,
    loadedItem: false
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_ITEM_URL: {
            return { ...state, currentItemUrl: payload };
        }
        case SET_ITEM_ID: {
            return { ...state, currentItemId: payload };
        }
        case SET_ITEM_DOWNLOAD: {
            return { ...state, currentItemDownload: payload };
        }
        case SET_LOADED_ITEM: {
            return { ...state, loadedItem: payload };
        }
        default:
            return state;
    }
}


import { SET_IMAGE_LIST } from "../actions/images";

const initialState = {
    currentImageList: null
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_IMAGE_LIST: {
            return { ...state, currentImageList: payload }
        }
        default:
            return state;
    }
}
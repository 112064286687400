import React, { useState, useEffect } from 'react'
import { setDriverInfo, setDriverExperience, setBookingReference, setLoadedData } from '../../store/actions/driver';
import { setItemUrl, setItemDownload, setItemId, setLoadedItem } from '../../store/actions/item';
import { setImageList } from '../../store/actions/images';
import { setVideoList } from '../../store/actions/videos';
import { setTrailerUrl, setTrailerUuid, setTrailerDownload, setTrailerList } from '../../store/actions/trailer';
import md5 from "md5";
import { useDispatch, useSelector } from 'react-redux'
import driver from '../../store/reducers/driver';
import { useNavigate, useParams } from 'react-router-dom';
import EnvironmentServices from '../../services/EnviromentServices';

function useShareActions() {
    const apiUrl = EnvironmentServices.getShareApiUrl();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchData = async (mediaId, experienceId) => {
        try {
            const response = await fetch(`${apiUrl}?mediaId=${mediaId}&experienceId=${experienceId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            if (data?.error === "The ids provided don't match any record") {
                throw new Error('noMatch')
            }
            return data;
        } catch (error) {
            switch (error.message) {
                case 'NoMatch': return null; break;
                default: return null;
            }
        }
    }

    const getData = (mediaId, experienceId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await fetchData(mediaId, experienceId);
                if (data === 'noMatch') {
                    navigate('/error');
                }
                if (getCookie("mediaId")) {
                    saveData(data);
                }
                resolve(data);
            } catch (error) {
                reject(error);
            }
        });
    }

    const isShared = async (id, uuidExperience) => {
        const auth_token = getCookie("jwt");
        const apiUrl = EnvironmentServices.getPublishApiUrl();
        const settings = {
            method: 'POST',
            body: JSON.stringify({
                mediaId: id,
                experienceId: uuidExperience
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: "Basic " + auth_token
            }
        };

        try {
            const response = await fetch(`${apiUrl}`, settings);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
            return null; // Return null or handle the error as needed
        }
    }

    const getCookie = (value) => {
        let cookie = {};
        document.cookie.split(';').forEach(function (el) {
            let [key, value] = el.split('=');
            cookie[key.trim()] = value;
        })
        return cookie[value];
    }

    const saveKeys = (mediaId, experienceId, auth_token) => {
        document.cookie = `mediaId=${mediaId}; path=/; samesite=none; domain=.mercedes-benzworld.co.uk; secure;`;
        document.cookie = `uuidExperience=${experienceId}; path=/; domain=.mercedes-benzworld.co.uk; samesite=none; secure;`;
        document.cookie = `jwt=${auth_token}; path=/; domain=.mercedes-benzworld.co.uk; samesite=none; secure;`;
        return;
    }

    const saveData = (data) => {
        const driverName = data?.details?.driver_name;
        const driverExperience = data?.Item?.Vehicle;
        const bookRef = data?.details?.booking_reference;
        const itemUrl = data?.Item?.url;
        const itemId = data?.Item?.id;
        const itemDownload = data?.Item?.DownloadUrl;
        dispatch(setDriverInfo(driverName));
        dispatch(setDriverExperience(driverExperience));
        dispatch(setItemUrl(itemUrl));
        dispatch(setItemId(itemId));
        dispatch(setItemDownload(itemDownload));
        dispatch(setLoadedItem(true));
        dispatch(setLoadedData(true));
        return;
    }

    const reloadData = async (mediaId, experienceId) => {
        const data = await getData(mediaId, experienceId);
        const auth_token = data?.message?.jwt;
        if (!data || data === 'noMatch') {
            navigate('/error');
        }
        saveData(data?.message);
        saveKeys(mediaId, experienceId, auth_token);
        return;
    }

    return {
        getCookie,
        getData,
        fetchData,
        reloadData,
        isShared
    };
}

export default useShareActions

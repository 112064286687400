import { SET_IS_PLAYED, SET_IS_PAUSED, SET_ID_CURRENT_PLAYING, ADD_VIDEO, REMOVE_VIDEO } from "../actions/video";

const initialState = {
    videos: {},
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case ADD_VIDEO: {
            return {
                ...state,
                videos: {
                    ...state.videos,
                    [payload.id]: {
                        isPlayed: false,
                        isPaused: true,
                    },
                },
            };
        }
        case REMOVE_VIDEO: {
            const { [payload.id]: removeVideo, ...remainingVideos } = state.videos;
            return {
                ...state,
                videos: remainingVideos,
            };
        }
        case SET_IS_PLAYED: {
            const { isPlayed, id } = payload;
            return {
                ...state,
                videos: {
                    ...state.videos,
                    [id]: {
                        ...state.videos[id],
                        isPlayed: isPlayed,
                    },
                },
            };
        }
        case SET_IS_PAUSED: {
            const { isPaused, id } = payload;
            return {
                ...state,
                videos: {
                    ...state.videos,
                    [id]: {
                        ...state.videos[id],
                        isPaused: isPaused
                    },
                },
            };
        }
        case SET_ID_CURRENT_PLAYING:
            return { ...state, currentIdVideoPlaying: payload };
        default:
            return state;
    }
}



import { SET_DRIVER_INFO, SET_DRIVER_EXPERIENCE, SET_BOOKING_REFERNCE, SET_LOADED_DATA } from "../actions/driver";

const initialState = {
    currentDriverInfo: null,
    currentDriverExperienceInfo: null,
    currentBookingReference: null,
    loadedData: false
}

export default (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case SET_DRIVER_INFO: {
            return { ...state, currentDriverInfo: payload };
        }
        case SET_DRIVER_EXPERIENCE: {
            return { ...state, currentDriverExperienceInfo: payload };
        }
        case SET_BOOKING_REFERNCE: {
            return { ...state, currentBookingReference: payload };
        }
        case SET_LOADED_DATA: {
            return { ...state, loadedData: payload };
        }
        default:
            return state;
    }
}
import React from 'react'
import Error from '../../components/core/Error/Error'

function ErrorPage({ missingUuid = true, brExpired = false }) {
    return (
        <Error missingUuid={missingUuid} brExpired={brExpired} />
    )
}

export default ErrorPage

const { REACT_APP_ENV } = process.env;

const ENVIRONMENT = {
    DEV: 'DEV',
    TEST: 'TEST',
    STAGE: 'STAGE',
    PROD: 'PROD'
}

export default class EnvironmentServices {
    static isDev = () => {
        return REACT_APP_ENV === ENVIRONMENT.DEV;
    }
    static isTest = () => {
        return REACT_APP_ENV === ENVIRONMENT.TEST;
    }

    static isStage = () => {
        return REACT_APP_ENV === ENVIRONMENT.STAGE;
    }

    static isProd = () => {
        return REACT_APP_ENV === ENVIRONMENT.PROD;
    }

    static getApiUrl = () => {
        switch (REACT_APP_ENV) {
            case 'DEV': { return 'https://api.discoverydam.com/stage' };
            case 'STAGE': { return 'https://api.discoverydam.com/stage' };
            case 'PROD': { return 'https://api.discoverydam.com/prod' };
            default: return 'https://api.discoverydam.com/prod';
        }
    }

    static getShareApiUrl = () => {
        switch (REACT_APP_ENV) {
            case 'DEV': { return 'https://api.discoverydam.com/stage/mediaInfo' };
            case 'STAGE': { return 'https://api.discoverydam.com/stage/mediaInfo' };
            case 'PROD': { return 'https://api.discoverydam.com/prod/mediaInfo' };
            default: return 'https://api.discoverydam.com/prod/mediaInfo';
        }
    }

    static getPublishApiUrl = () => {
        switch (REACT_APP_ENV) {
            case 'DEV': { return 'https://api.discoverydam.com/stage/publishMedia' };
            case 'STAGE': { return 'https://api.discoverydam.com/stage/publishMedia' };
            case 'PROD': { return 'https://api.discoverydam.com/prod/publishMedia' };
            default: return 'https://api.discoverydam.com/prod/publishMedia';
        }
    }
}
import React from 'react'
import Home from '../../components/core/Home/Home'
import classes from "./gallery.module.scss"

function Gallery() {
    return (
        <Home />
    )
}

export default Gallery

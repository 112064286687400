import React from 'react'
import classes from './CustomLeftArrow.module.scss'

function CustomLeftArrow() {
    return (
        <button className={`${classes.buttonLeft} border-0 rounded-0 btn btn-primary btn-lg bi bi-caret-right-fill`} >
            <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Arrow left" d="M9.90852 25L-1.81198e-05 12.1951L9.90852 0H14.4817L5.64023 12.1951L14.4817 25H9.90852Z" fill="white" />
            </svg>
        </button>
    )
}

export default CustomLeftArrow

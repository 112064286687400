export const SET_ITEM_URL = "SET_ITEM_URL";
export const SET_ITEM_ID = "SET_ITEM_ID";
export const SET_ITEM_DOWNLOAD = "SET_ITEM_DOWNLOAD";
export const SET_LOADED_ITEM = "SET_LOADED_ITEM";

export const setItemUrl = (payload) => {
    return { type: SET_ITEM_URL, payload };
}

export const setItemId = (payload) => {
    return { type: SET_ITEM_ID, payload };
}

export const setItemDownload = (payload) => {
    return { type: SET_ITEM_DOWNLOAD, payload };
}

export const setLoadedItem = (payload) => {
    return { type: SET_LOADED_ITEM, payload };
}
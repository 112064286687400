import { React, useState, useEffect, useRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../../components/core/Navbar/Navbar'
import Footer from '../../components/core/Footer/Footer'
import Loading from '../../components/core/Loading/Loading'
import { DiscoveryVideoPlayer } from "@hyperstream/discoveryvideoplayer"
import '@hyperstream/discoveryvideoplayer/dist/index.css';
import useData from '../../components/hooks/useData';
import VideoTest from '../../assets/videos/de-test-clip-1.mp4'
import Trailer from '../../assets/videos/20230804_114854206_RNPRO-355_Default_Driver_RN_Trailer.mp4'
import ImgBackground from '../../assets/images/share-background.png'
import classes from './Share.module.scss'
import { EXPERIENCES } from '../../config/cards/cardImage'
import { useSelector } from 'react-redux';

function Share() {
    const [isLoading, setIsLoading] = useState(true);
    const [currentData, setCurrentData] = useState();
    const [options, setOptions] = useState();
    const [loadedData, setLoadedData] = useState([]);
    const [isVideo, setIsVideo] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [isTrailer, setIsTrailer] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [videoUrl, setVideoUrl] = useState();
    const [trailerUrl, setTrailerUrl] = useState();

    const data = useData();

    const { filetype, uuid, uuidexperience } = useParams();

    const bookingReference = useSelector((state) => state.driver.currentBookingReference);
    const driverInfos = useSelector((state) => state.driver);
    const isLoaded = useSelector((state) => state.driver.loadedData);
    const itemUrl = useSelector((state) => state.item.currentItemUrl);
    const itemId = useSelector((state) => state.item.currentItemId);

    const videoOptions = {
        autoloop: true,
        autoplay: true,
        fluid: true,
        loop: true,
        controls: true,
        preload: 'auto',
        muted: true,
        durationDisplay: false,
        playbackRateMenuButton: false,
        sources: [{
            src: videoUrl,
            type: 'application/x-mpegURL',
            withCredentials: true
        }]
    };

    const trailerOptions = {
        autoloop: true,
        autoplay: true,
        fluid: true,
        loop: true,
        controls: true,
        preload: 'auto',
        muted: true,
        durationDisplay: false,
        playbackRateMenuButton: false,
        sources: [{
            src: trailerUrl,
            type: 'application/x-mpegURL',
            withCredentials: true
        }]
    };

    const video = document.getElementsByTagName('video');
    video.src = trailerUrl;
    video.autoplay = true;
    video.controls = true;
    video.muted = true;

    useEffect(() => {
        if (filetype) {
            if (filetype === 'video') {
                setIsVideo(true);
            } else if (filetype === 'image') {
                setIsImage(true);
            } else if (filetype === 'trailer') {
                setIsTrailer(true);
            }
        }
    }, [filetype])

    useEffect(() => {
        if (itemUrl) {
            if (filetype === 'video') {
                setVideoUrl(itemUrl);
            } else if (filetype === 'image') {
                setImageUrl(imageUrl);
            } else if (filetype === 'trailer') {
                setTrailerUrl(itemUrl);
            }
        }
    }, [itemUrl])

    useEffect(() => {
        if (!bookingReference) {
            data.loadSharedData(uuid, uuidexperience);
        }
    }, [])

    useEffect(() => {
        if (isLoaded) {
            setIsLoading(false);
        }
    }, [isLoaded]);

    return (
        <>
            {isLoading &&
                <Loading />
            }

            <div className={`${classes.videoContainer}`} id='background-video'>
                <img className='w-100 h-100' src={ImgBackground}></img>
            </div>
            {!isLoading &&
                <div className={`container-fluid ${isImage ? classes.imageContainerPadding : classes.videoContainerPadding}`}>
                    <div className={`row pt-5 px-lg-5 d-flex justify-content-center align-items-center`}>
                        <div className={`col-12 col-lg-4 py-3 py-md-0 mb-3 mb-md-0 pl-md-2 px-md-3 my-lg-5 d-flex justify-content-center`}>
                            <div>
                                <div className={`${classes.driverName}`}>{driverInfos?.currentDriverInfo}</div>
                                {(isTrailer || isImage) &&
                                    <div className={`${classes.driverExperience}`}>{EXPERIENCES.LABELS[driverInfos?.currentDriverExperienceInfo] ? EXPERIENCES.LABELS[driverInfos?.currentDriverExperienceInfo] : 'Driving'} Experience</div>
                                }
                                {isVideo &&
                                    <div className={`${classes.driverExperience}`}>{EXPERIENCES.LABELS[driverInfos?.currentDriverExperienceInfo] ? EXPERIENCES.LABELS[driverInfos?.currentDriverExperienceInfo] : 'Driving'} Experience</div>
                                }
                                <div className={`${classes.trailerLabel} mb-md-3 mb-lg-0`}>{isImage ? 'Image' : ''} {isVideo ? 'Video' : ''} {isTrailer ? 'Trailer' : ''}</div>
                            </div>
                        </div>
                        <div className={`col-md-12 col-lg-7 my-lg-3`}>
                            {!isLoading && (isVideo || isTrailer) &&
                                (<div className={`${classes.videoPlayer} mt-md-3 ml-lg-4`}>
                                    <DiscoveryVideoPlayer options={isTrailer ? trailerOptions : videoOptions} />
                                </div>)
                            }
                            {!isLoading && isImage &&
                                (<div>
                                    <img className={`${classes.image}`} src={itemUrl} />
                                </div>)
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                            <p className={`${classes.visitLabel} d-flex flex-column flex-sm-row justify-content-center`}>You too can enjoy a Driving Experience at Mercedes-Benz World - <a className={`${classes.visitLink} d-flex justify-content-center justify-content-sm-end align-items-center pl-1`} href='https://www.mercedes-benzworld.co.uk/'>Book here</a></p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Share
